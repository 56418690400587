// Bootstrap Imports
import 'popper.js';

/* eslint-disable no-unused-vars */
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';
/* eslint-enable no-unused-vars */

// import './animations';

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	const html = document.querySelector('html');
	html.classList.remove('no-js');
	html.classList.add('js');
}

// Optimize the scroll events for better browser performance.
//
// Broadcasts optimizedScroll event on the window.
// Use the same way you would add a window scroll listener.
// jQuery(window).on('optimizedScroll', ...);
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	window.addEventListener('scroll', () => {
		if (scrollWaiting) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout(endScrollHandle);

		window.dispatchEvent(new Event('optimizedScroll'));

		setTimeout(function () {
			scrollWaiting = false;
		}, 100);

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout(function () {
			window.dispatchEvent(new Event('optimizedScroll'));
		}, 200);
	});
}

function mainNavigationScroll() {
	window.addEventListener('optimizedScroll', () => {
		const scrollTop = window.scrollTop;
		const $siteHeader = document.querySelector('.site-header');
		const $siteHeaderSpacer = document.querySelector(
			'.site-header__spacer'
		);

		if (scrollTop > 0) {
			$siteHeader.classList.remove('top');
			$siteHeaderSpacer.classList.remove('top');
		} else {
			$siteHeader.classList.add('top');
			$siteHeaderSpacer.classList.add('top');
		}
	});
}

function mainNavigation() {
	// Load the scroll state
	mainNavigationScroll();

	const siteNavigation = document.getElementById('site-navigation');
	const navCollapseEl = document.getElementById('main-menu-container');
	const navSearch = document.getElementById('nav-search');
	const navSearchToggle = document.getElementById('nav-search-toggle');

	navSearchToggle.addEventListener('click', function () {
		navSearch.classList.toggle('active');
	});

	navCollapseEl.addEventListener('show.bs.collapse', function () {
		siteNavigation.classList.add('active');
	});
	navCollapseEl.addEventListener('hide.bs.collapse', function () {
		siteNavigation.classList.remove('active');
	});

	window.addEventListener('resize', function () {
		if (
			navCollapseEl !== undefined &&
			navCollapseEl.classList.contains('show')
		) {
			const navCollapse = Collapse.getInstance(navCollapseEl);
			navCollapse.hide();
		}
	});
}

// function anchorSmoothScrolling() {
// 	const spacing = 86;
// 	jQuery('a[href*=\\#]:not([href=\\#])').click(function () {
// 		if (
// 			location.pathname.replace(/^\//, '') ===
// 				this.pathname.replace(/^\//, '') ||
// 			location.hostname === this.hostname
// 		) {
// 			let target = jQuery(this.hash);
// 			target = target.length
// 				? target
// 				: jQuery('[name=' + this.hash.slice(1) + ']');
// 			if (target.length) {
// 				jQuery('html,body').animate(
// 					{
// 						scrollTop: target.offset().top - spacing,
// 					},
// 					1000
// 				);
// 				return false;
// 			}
// 		}
// 	});
//
// 	if (window.location.hash) {
// 		jQuery('html,body').animate(
// 			{
// 				scrollTop: jQuery(window.location.hash).offset().top - spacing,
// 			},
// 			1000
// 		);
// 	}
// }

/**
 * Use Bootstrap framework styles with Ninja Forms validation.
 */
function ninjaValidation() {
	if (typeof Marionette !== 'undefined') {
		const ninjaValidationController = Marionette.Object.extend({
			initialize() {
				this.listenTo(
					Backbone.Radio.channel('fields'),
					'add:error',
					this.addError
				);

				this.listenTo(
					Backbone.Radio.channel('fields'),
					'remove:error',
					this.removeError
				);
			},

			addError(response) {
				const $el = document.querySelector('#nf-field-' + response.id);
				$el.classList.add('is-invalid');
			},

			removeError(response) {
				const $el = document.querySelector('#nf-field-' + response.id);
				$el.classList.remove('is-invalid');
			},
		});

		// Instantiate our validation controller, defined above.
		new ninjaValidationController();
	}
}

function filterPortfolio() {
	const portfolios = document.getElementsByClassName('block-portfolio');
	if (portfolios.length <= 0) return;
	// init Isotope
	const $grid = jQuery('.grid').isotope({
		itemSelector: '.block-portfolio__images',
		layoutMode: 'fitRows',
	});

	// bind filter button click
	jQuery('.filters-btn-group').on('click', 'button', function () {
		const filterValue = jQuery(this).attr('data-filter');
		$grid.isotope({ filter: filterValue });
	});

	// change is-checked class on buttons
	jQuery('.portfolio-btn-group').each(function (i, buttonGroup) {
		const $buttonGroup = jQuery(buttonGroup);
		$buttonGroup.on('click', 'button', function () {
			$buttonGroup.find('.is-checked').removeClass('is-checked');
			jQuery(this).addClass('is-checked');
		});
	});
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

// function addAutoSpacer() {
// 	const allBlocks = [
// 		...document.querySelectorAll('[class^=wp-block]'),
// 		...document.querySelectorAll('[class^=block]'),
// 	];
// 	const blocks = allBlocks.filter((el) => {
// 		return !allBlocks.includes(el.parentNode);
// 	});
// 	blocks.forEach((el) => {
// 		el.classList.add('auto-spacer');
// 	});
// }

/**
 * On Loads
 */
document.addEventListener('DOMContentLoaded', () => {
	fouc();
	optimizeScroll();
	// anchorSmoothScrolling();
	ninjaValidation();
	filterPortfolio();
});

jQuery(function () {
	mainNavigation();
});

// addAutoSpacer();
