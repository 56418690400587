function videoGalleryEvent() {
	const videoGalleries = document.querySelectorAll('.block-video-gallery');
	const pitchModal = document.getElementById('pitch-video');

	videoGalleries.forEach((gallery) => {
		const btnLoadMore = gallery.querySelector('.btn.btn-primary.load-more');

		btnLoadMore.addEventListener('click', loadMore);
	});

	if (pitchModal)
		pitchModal.addEventListener('hidden.bs.modal', removeModalContent);
}

function removeModalContent() {
	const pitchModal = document.getElementById('pitch-video');
	const content = pitchModal.querySelector('.modal-content > .modal-body');

	content.innerHTML = '';
}

function loadMore() {
	const countDisplayVideo = 6;

	const hiddenVideo = this.parentNode.querySelectorAll(
		'.video-gallery__video.is-hidden'
	);

	const videoTobeDisplay = Array.prototype.slice
		.call(hiddenVideo)
		.splice(0, countDisplayVideo);

	videoTobeDisplay.forEach((video) => {
		video.classList.remove('is-hidden');

		gsap.from(video, { opacity: 0, y: 100 });
	});

	if (hiddenVideo.length < countDisplayVideo) this.remove();
}

document.addEventListener('DOMContentLoaded', () => {
	videoGalleryEvent();
});
