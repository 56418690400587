function pitchEvent() {
	const btnPitchVideo = document.querySelectorAll('.btn.pitch-modal');

	btnPitchVideo.forEach((btn) => {
		btn.addEventListener('click', initModalContent);
	});
}

function initModalContent() {
	const pitchModal = document.getElementById('pitch-video');
	const modalBody = pitchModal.querySelector('.modal-body');

	modalBody.innerHTML = '';

	const modalContent = document.createElement('div');
	modalContent.setAttribute('class', 'content');

	const logoLink = this.dataset.logoLink;
	const videoUrl = this.dataset.videoUrl;
	const portfolioLink = this.dataset.link;
	const content = this.querySelector('#pitch-modal-content');
	const iframe = this.querySelector('iframe');

	// image
	const image = document.createElement('img');
	image.src = logoLink;
	image.width = 300;
	image.height = 300;
	image.setAttribute('alt', 'Logo');

	modalContent.appendChild(image);

	if (content) {
		const tempContent = content.cloneNode(true);
		modalContent.appendChild(tempContent);
	}

	if (portfolioLink) {
		const learnMore = document.createElement('a');
		learnMore.href = portfolioLink;

		learnMore.setAttribute('target', '_blank');
		learnMore.setAttribute('class', 'modal-pitch-link');

		learnMore.textContent = 'Learn More';

		modalContent.appendChild(learnMore);
	}

	if (iframe) {
		const tempIframe = iframe.cloneNode(true);
		modalBody.appendChild(tempIframe);
	}

	if (videoUrl) {
		// video
		const video = document.createElement('video');
		video.src = videoUrl;
		video.setAttribute('controls', true);
		modalBody.appendChild(video);
	}

	modalBody.appendChild(modalContent);
}

document.addEventListener('DOMContentLoaded', () => {
	pitchEvent();
});
